exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credenciamento-js": () => import("./../../../src/pages/credenciamento.js" /* webpackChunkName: "component---src-pages-credenciamento-js" */),
  "component---src-pages-edicoes-anteriores-js": () => import("./../../../src/pages/edicoes-anteriores.js" /* webpackChunkName: "component---src-pages-edicoes-anteriores-js" */),
  "component---src-pages-final-sapientia-2022-js": () => import("./../../../src/pages/final-sapientia-2022.js" /* webpackChunkName: "component---src-pages-final-sapientia-2022-js" */),
  "component---src-pages-final-sapientia-2023-js": () => import("./../../../src/pages/final-sapientia-2023.js" /* webpackChunkName: "component---src-pages-final-sapientia-2023-js" */),
  "component---src-pages-finalistas-2021-js": () => import("./../../../src/pages/finalistas-2021.js" /* webpackChunkName: "component---src-pages-finalistas-2021-js" */),
  "component---src-pages-finalistas-js": () => import("./../../../src/pages/finalistas.js" /* webpackChunkName: "component---src-pages-finalistas-js" */),
  "component---src-pages-imprensa-js": () => import("./../../../src/pages/imprensa.js" /* webpackChunkName: "component---src-pages-imprensa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-las-olimpiadas-del-futuro-js": () => import("./../../../src/pages/las-olimpiadas-del-futuro.js" /* webpackChunkName: "component---src-pages-las-olimpiadas-del-futuro-js" */),
  "component---src-pages-lp-canada-js": () => import("./../../../src/pages/lp-canada.js" /* webpackChunkName: "component---src-pages-lp-canada-js" */),
  "component---src-pages-prova-js": () => import("./../../../src/pages/prova.js" /* webpackChunkName: "component---src-pages-prova-js" */),
  "component---src-pages-sucess-js": () => import("./../../../src/pages/sucess.js" /* webpackChunkName: "component---src-pages-sucess-js" */),
  "component---src-pages-triple-frontera-js": () => import("./../../../src/pages/triple-frontera.js" /* webpackChunkName: "component---src-pages-triple-frontera-js" */),
  "component---src-pages-triplice-fronteira-js": () => import("./../../../src/pages/triplice-fronteira.js" /* webpackChunkName: "component---src-pages-triplice-fronteira-js" */),
  "component---src-pages-votacao-finalistas-2023-js": () => import("./../../../src/pages/votacao-finalistas-2023.js" /* webpackChunkName: "component---src-pages-votacao-finalistas-2023-js" */)
}

